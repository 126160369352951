var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { getIconDescription, Icon, TicketIcon } from '@components/icon';
import { PriceButton } from '@components/priceButton/priceButton';
import { t } from '@containers/helpers/tests';
import { actions as eventsActions } from '@logic/events';
import { formatRemainingTime } from '@logic/helpers/date';
import { getTransactionSide } from '@logic/listings';
import { stringifyNormalizedSeatingInfo } from '@logic/seatingInfo';
import { getBuyCosts, getSellCosts } from '@logic/transactions';
import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { Timer } from 'src/client/containers/TransactionsFlow/components/timer/timer';
import { actions } from '@containers/DetailsPages/pages/EventDetails/useEventTabState';
import { Fee } from '@containers/DetailsPages/pages/EventDetails/components/Fee';
import { RestrictionsInfo } from '@containers/DetailsPages/pages/EventDetails/components/RestrictionsInfo';
import { SeatingMapOnListing } from '@containers/DetailsPages/pages/EventDetails/components/SeatingMapOnListing';
import styles from '@containers/DetailsPages/pages/EventDetails/components/EventPageListings/eventPageListings.less';
import { useViewportInfo } from '@components/viewPortInfo/useViewportInfo.hook';
import { isMobile } from '@components/viewPortInfo/viewPort.utils';
export var EventPageListings = function (_a) {
    var listings = _a.listings, onListingSelect = _a.onListingSelect, sort = _a.sort, sortReverse = _a.sortReverse, transactionSide = _a.transactionSide, onListingHover = _a.onListingHover, settings = _a.settings, filterValue = _a.filterValue, dispatch = _a.dispatch, eventId = _a.eventId, seatingPlan = _a.seatingPlan, onOpenFilter = _a.onOpenFilter, buyOnly = _a.buyOnly;
    var handleSortChange = useCallback(function (sortColumn) { dispatch(actions.changeSort(sortColumn)); }, [dispatch]);
    return (React.createElement("div", { className: styles.listingsContainer }, !!listings.length &&
        React.createElement("div", __assign({}, t("Listing-" + getTransactionSide(transactionSide))),
            React.createElement(EventPageListingsHeader, { onSortChange: handleSortChange, sort: sort, sortReverse: sortReverse, buyOnly: buyOnly }),
            listings.map(function (listing) { return React.createElement(EventPageListingRow, { key: listing.id, listing: listing, onSelect: onListingSelect, type: transactionSide, onListingHover: onListingHover, settings: settings, filterValue: filterValue, eventId: eventId, seatingPlan: seatingPlan, onOpenFilter: onOpenFilter }); }))));
};
var ownerBusinessInfo = {
    isBusinessSeller: 'Seller is a trader',
    isEventOrganiser: 'Seller is affiliated with the event organiser',
    affiliatedWithPlatform: 'Seller is affiliated with the platform',
};
function BusinessInfo(_a) {
    var ownerInfo = _a.ownerInfo;
    if (!ownerInfo || Object.values(ownerInfo).every(function (_) { return !_; })) {
        return null;
    }
    return React.createElement(Popup, { trigger: React.createElement(Icon, { icon: 'is-business', width: 16, height: 16 }), content: React.createElement(React.Fragment, null, Object.entries(ownerInfo)
            .filter(function (_a) {
            var _b = __read(_a, 2), v = _b[1];
            return v;
        })
            .map(function (_a) {
            var _b = __read(_a, 1), k = _b[0];
            return React.createElement("div", { key: k }, ownerBusinessInfo[k]);
        })) });
}
function ReservationInfoPopup(_a) {
    var children = _a.children;
    return React.createElement(Popup, { hoverable: true, basic: true, content: React.createElement(React.Fragment, null,
            "These tickets are currently reserved by another customer.",
            React.createElement("br", null),
            React.createElement("br", null),
            "  They may become available again so please refresh the page to see current availability."), trigger: children });
}
function EventPageListingRow(_a) {
    var listing = _a.listing, type = _a.type, settings = _a.settings, filterValue = _a.filterValue, onSelect = _a.onSelect, onListingHover = _a.onListingHover, eventId = _a.eventId, seatingPlan = _a.seatingPlan, onOpenFilter = _a.onOpenFilter;
    var dispatch = useDispatch();
    var media = useViewportInfo().media;
    var seatingPlanAction = isMobile(media) ? onOpenFilter : function () { return; };
    var costsSummary = useMemo(function () {
        var quantity = filterValue !== 'any' ? filterValue : type === 'buy' ? 1 : listing.maxTreadableQuantities;
        if (!settings) {
            return {
                ticketsCost: listing.price.value,
                ticketFee: 0,
                transactionFeeGross: 0,
                transactionFeeNet: 0,
                transactionFeeVAT: 0,
                deliveryCostGross: 0,
                deliveryCostNet: 0,
                deliveryCostVAT: 0,
                total: listing.price.value * quantity
            };
        }
        var costFn = type === 'sell' ? getSellCosts : getBuyCosts;
        return costFn(listing.price.value, listing.ticketType, quantity, settings, false); // todo?
    }, [listing, type, filterValue, settings]);
    var handlingFee = useMemo(function () {
        var deliveryCostGross = costsSummary.deliveryCostGross, ticketFee = costsSummary.ticketFee;
        return {
            value: listing.ownedByMe || type === 'sell' ? ticketFee + deliveryCostGross : deliveryCostGross,
            currency: listing.price.currency,
        };
    }, [listing, type, costsSummary]);
    var handleClick = useCallback(function () { return onSelect(listing); }, [onSelect, listing]);
    var handleHover = useCallback(function () { return onListingHover(listing); }, [onListingHover, listing]);
    var handleLeave = useCallback(function () { return onListingHover(null); }, [onListingHover]);
    var seatingInfo = function () {
        return listing.seatingInfo.map(stringifyNormalizedSeatingInfo).join(', ');
    };
    var getDisplayQuantity = function (filterValue, listing) {
        return listing.isReservation || filterValue === 'any'
            ? listing.maxTreadableQuantities
            : filterValue;
    };
    var displayQuantity = getDisplayQuantity(filterValue, listing);
    var listingPrice = useMemo(function () {
        if (listing.ownedByMe || type === 'sell') {
            return listing.price;
        }
        return {
            value: listing.price.value + costsSummary.ticketFee,
            currency: listing.price.currency
        };
    }, [listing, type, costsSummary.ticketFee]);
    var transactionButton = React.createElement(PriceButton, __assign({ price: listingPrice, transactionSide: type, edit: listing.ownedByMe, center: true, disabled: !!listing.expirationTime, onClick: handleClick, hideFrom: true }, t('Transaction')));
    var reservationButton = (React.createElement(ReservationInfoPopup, null,
        React.createElement(PriceButton, __assign({ price: listingPrice, transactionSide: type, edit: listing.ownedByMe, center: true, disabled: !!listing.expirationTime, hideFrom: true }, t('Reservation')))));
    var editButton = React.createElement(PriceButton, __assign({ price: listingPrice, transactionSide: type, edit: listing.ownedByMe, center: true, disabled: !!listing.expirationTime, onClick: handleClick, hideFrom: true }, t('Edit')));
    var button = listing.expirationTime
        ? reservationButton
        : listing.ownedByMe
            ? editButton
            : transactionButton;
    return (React.createElement("div", { className: cn(styles.listingRow, styles.bordered), onMouseOver: handleHover, onMouseLeave: handleLeave },
        React.createElement(SeatingMapOnListing, { isLoadingSeatingPlan: false, listing: listing, seatingPlan: seatingPlan, onOpenFilter: seatingPlanAction }),
        React.createElement("div", { className: styles.sectionBlock },
            React.createElement("div", null,
                React.createElement("div", { className: styles.ticketType },
                    React.createElement(TicketIcon, { ticketType: listing.ticketType, hasTicketsAssigned: !!listing.hasTicketsAssigned, width: 40, hideDescription: true, className: styles.icon }),
                    displayQuantity,
                    " x ",
                    getIconDescription(listing.ticketType)),
                React.createElement("div", { className: styles.mainColumn },
                    React.createElement("div", { className: styles.section },
                        seatingInfo(),
                        React.createElement(RestrictionsInfo, { restrictions: listing.restrictions }))),
                !!listing.expirationTime &&
                    React.createElement(ReservationInfoPopup, null,
                        React.createElement("div", { className: styles.reserved },
                            "Reserved! Available in ",
                            React.createElement(Timer, { render: formatRemainingTime, endTime: listing.expirationTime, onTimeout: function () { return dispatch(eventsActions.reloadEventDetails(eventId)); } })))),
            React.createElement("div", { className: styles.infoSection },
                React.createElement(BusinessInfo, { ownerInfo: listing.ownerInfo }))),
        React.createElement("div", { className: styles.buttonSection },
            button,
            React.createElement(Fee, { faceValue: listing.faceValue, fee: handlingFee, disabled: !!listing.expirationTime, transactionSide: type, ownedByMe: listing.ownedByMe }))));
}
function EventPageListingsHeader(_a) {
    var _b, _c, _d;
    var sort = _a.sort, sortReverse = _a.sortReverse, onSortChange = _a.onSortChange, buyOnly = _a.buyOnly;
    // const handleSortByQuantity = useCallback(() => onSortChange('quantity'), [onSortChange]);
    var handleSortBySection = useCallback(function () { return onSortChange('section'); }, [onSortChange]);
    var handleSortByPrice = useCallback(function () { return onSortChange('price'); }, [onSortChange]);
    return (React.createElement("div", { className: cn(styles.sortingRow, (_b = {}, _b[styles.buyOnly] = buyOnly, _b)) },
        React.createElement("div", { onClick: handleSortBySection, className: styles.sortHeader },
            "Section",
            React.createElement(Icon, { height: 16, width: 16, className: cn((_c = {}, _c[styles.visibleIcon] = 'section' === sort, _c)), icon: sortReverse ? 'caretDown' : 'caretUp' })),
        React.createElement("div", { onClick: handleSortByPrice, className: cn(styles.sortHeader, styles.rightAllign) },
            "Price Per Ticket",
            React.createElement(Icon, { height: 16, width: 16, className: cn((_d = {}, _d[styles.visibleIcon] = 'price' === sort, _d)), icon: sortReverse ? 'caretDown' : 'caretUp' }))));
}
