var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var _a;
import { actions as eventsActions } from '@logic/events';
import { push, replace } from 'connected-react-router';
import { differenceInMilliseconds, isBefore, subSeconds } from 'date-fns';
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { routes } from 'src/client/routing/routes';
import uuid from 'uuid/v1';
import { tryMapError } from './errorCodeMapper';
import { parseJwt } from './helpers/authentication';
import { getCookie, setCookie } from './helpers/cookies';
import { getSession, getUser, removeSession, removeUser, setSession, setUser } from './helpers/localStorage';
import request from './helpers/request';
import { authApi, registerReset } from './registration';
import { assignReservationToUser, clearReservations, reloadReservationFromCache } from './reservations';
import { loadGlobalSettings, loadUserSettings } from './userSettings';
import { loadPromotedPerformers } from './performer';
import get from 'lodash/get';
export var USER_COOKIE_KEY = 'welcomeback';
export var clearStore = createAction('clear whole store');
export var sessionKeep = createAction('keep session');
export var updateUserName = createAction('update user name', function (_a) {
    var fullName = _a.fullName, _b = _a.disableToasts, disableToasts = _b === void 0 ? false : _b;
    return ({ fullName: fullName, disableToasts: disableToasts });
});
export var updatePhoneNumber = createAction('update phone number', function (phoneNumber) { return ({ phoneNumber: phoneNumber }); });
export var updateUserDetails = createAction('update user detail', function (_a) {
    var details = _a.details, _b = _a.disableToasts, disableToasts = _b === void 0 ? false : _b;
    return ({ details: details, disableToasts: disableToasts });
});
export var updateUserDetailsSuccess = createAction('update user details success');
export var updateUserDetailsFailure = createAction('update user details failure');
export var getUserDetails = createAction('get user details');
export var userDetailsLoaded = createAction('fetching user details succeed', function (details) { return ({ details: details }); });
export var changeUserEmailAndDetails = createAction('update user email and details', function (email, password, details, resolve, reject) { return ({
    email: email,
    password: password,
    details: details,
    resolve: resolve,
    reject: reject,
}); });
export var changeUserEmail = createAction('update user email and details', function (email, password, resolve, reject) { return ({
    email: email,
    password: password,
    resolve: resolve,
    reject: reject,
}); });
export var changeUserEmailAndDetailsSuccess = createAction('update user email and details success', function (email) { return ({ email: email }); });
export var changeUserEmailAndDetailsFailure = createAction('update user email and details failure');
export var requestResetUserPassword = createAction('request reset user password', function (email, doNotRedirect, type) {
    if (doNotRedirect === void 0) { doNotRedirect = false; }
    return ({ email: email, doNotRedirect: doNotRedirect, type: type });
});
export var requestResetUserPasswordSuccess = createAction('request reset user password success', function (email) { return ({ email: email }); });
export var requestResetUserPasswordFailure = createAction('request reset user password failure');
export var clearResetUserPassword = createAction('clear reset user password form');
export var verifyResetPasswordToken = createAction('verify reset password token', function (email, token) { return ({ email: email, token: token }); });
export var resetUserPassword = createAction('reset user password', function (email, token, password, confirmPassword, type, next) { return ({
    email: email,
    password: password,
    confirmPassword: confirmPassword,
    token: token,
    type: type,
    next: next,
}); });
export var resetUserPasswordSuccess = createAction('reset user password success', function (email) { return ({ email: email }); });
export var resetUserPasswordFailure = createAction('reset user password failure', function (errors) { return errors; });
export var createAddress = createAction('create address', function (address, redirect) { return ({ address: address, redirect: redirect }); });
export var createAddressSuccess = createAction('create address success', function (userDetails) { return ({ userDetails: userDetails }); });
export var createAddressFailure = createAction('create address failure', function (errors) { return ({ errors: errors }); });
export var updateAddress = createAction('update address', function (address) { return ({ address: address }); });
export var updateAddressSuccess = createAction('update address success', function (address) { return ({ address: address }); });
export var updateAddressFailure = createAction('update address failure', function (errors) { return ({ errors: errors }); });
export var setDefaultAddress = createAction('set address as default', function (defaultAddressId) { return ({ defaultAddressId: defaultAddressId }); });
export var setDefaultAddressSuccess = createAction('set address as default success', function (details) { return ({ details: details }); });
export var setDefaultAddressFailure = createAction('set address as default failure', function (error) { return ({ error: error }); });
export var updatePreferences = createAction('update preferences', function (language, currency) { return ({ language: language, currency: currency }); });
export var updatePreferencesSuccess = createAction('update preferences success', function (details) { return ({ details: details }); });
export var updatePreferencesFailure = createAction('update preferences failure', function (error) { return ({ error: error }); });
export var deleteAddress = createAction('delete address', function (addressId) { return ({ addressId: addressId }); });
export var deleteAddressSuccess = createAction('delete address success', function (addressId) { return ({ addressId: addressId }); });
export var deleteAddressFailure = createAction('delete address failure', function (errors) { return ({ errors: errors }); });
export var login = createAction('send login credentials', function (data) { return data; });
export var loginStart = createAction('started sending login credentials request');
export var loginSuccess = createAction('login credentials success', function (session, userIdData) { return ({ session: session, userIdData: userIdData }); });
export var loginFailure = createAction('login credentials failure', function (error) { return error; });
export var loginReset = createAction('reset previous login');
export var externalLogin = createAction('send facebook login token', function (data) { return data; });
export var logout = createAction('logout user');
export var refresh = createAction('send refresh credentials', function (data) { return data; });
export var refreshSuccess = createAction('refresh session success', function (data) { return data; });
export var refreshFailure = createAction('refresh session failure', function (error) { return error; });
export var restoreSession = createAction('restore session');
var initialState = {
    session: null,
    userIdData: null,
    userDetails: null,
    error: null,
    isPending: false,
    resetPasswordErrors: [],
};
export var userReducer = createReducer((_a = {},
    _a[loginStart.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[loginSuccess.getType()] = function (state, _a) {
        var session = _a.session, userIdData = _a.userIdData;
        return (__assign(__assign({}, state), { error: null, isPending: false, session: session,
            userIdData: userIdData }));
    },
    _a[loginFailure.getType()] = function (state, error) { return (__assign(__assign({}, state), { error: error, isPending: false, session: null, userIdData: null })); },
    _a[loginReset.getType()] = function (state) { return (__assign(__assign({}, state), { error: null })); },
    _a[refreshSuccess.getType()] = function (state, session) { return (__assign(__assign({}, state), { error: null, session: session })); },
    _a[refreshFailure.getType()] = function (state, error) { return (__assign(__assign({}, state), { error: error, session: null, userIdData: null })); },
    _a[userDetailsLoaded.getType()] = function (state, _a) {
        var details = _a.details;
        var defaultDeliveryId = details.defaultDeliveryAddress ? details.defaultDeliveryAddress.id : null;
        var firstAddress = details.defaultDeliveryAddress ? [details.defaultDeliveryAddress] : [];
        var addresses = details.addresses ? details.addresses.filter(function (a) { return a.id !== defaultDeliveryId; }).sort(function (a, b) { return a.id > b.id ? 1 : -1; }) : [];
        return __assign(__assign({}, state), { error: null, isPending: false, userDetails: __assign(__assign({}, details), { addresses: __spreadArray(__spreadArray([], __read(firstAddress)), __read(addresses)) }) });
    },
    _a[updateUserDetails.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[updateUserDetailsSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[updateUserDetailsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[changeUserEmailAndDetails.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[changeUserEmailAndDetails.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[changeUserEmailAndDetails.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[requestResetUserPassword.getType()] = function (state) { return (__assign(__assign({}, state), { resetPasswordErrors: [] })); },
    _a[requestResetUserPassword.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[requestResetUserPasswordFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[requestResetUserPasswordSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[resetUserPasswordFailure.getType()] = function (state, resetPasswordErrors) { return (__assign(__assign({}, state), { isPending: false, resetPasswordErrors: resetPasswordErrors })); },
    _a[createAddress.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[createAddressSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[createAddressFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[updateAddress.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[updateAddressSuccess.getType()] = function (state, _a) {
        var address = _a.address;
        return (__assign(__assign({}, state), { userDetails: !state.userDetails ?
                null : __assign(__assign({}, state.userDetails), { addresses: ((state.userDetails && state.userDetails.addresses) || []).map(function (a) { return a.id === address.id ? address : a; }), defaultDeliveryAddress: !state.userDetails.defaultDeliveryAddress || state.userDetails.defaultDeliveryAddress.id === address.id ? address : state.userDetails.defaultDeliveryAddress }), isPending: false }));
    },
    _a[updateAddressFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[setDefaultAddress.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[setDefaultAddressSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[setDefaultAddressFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[updatePreferences.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[updatePreferencesSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[updatePreferencesFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[deleteAddress.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: true })); },
    _a[deleteAddressSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a[deleteAddressFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isPending: false })); },
    _a), initialState);
var selectSession = function (state) { return state.user.session; };
export var selectUserDetails = function (state) { return state.user.userDetails; };
var getErrorDetails = function (e) { return e.data ? e.data.map(function (_) { return _.description || _; }).join('; \n') : undefined; };
var getErrorMsg = function (msg, e) { return getErrorDetails(e) ? msg + ": " + getErrorDetails(e) : msg + "."; };
export var userApi = {
    getDetails: function () { return request('/api/user/'); },
    updateDetails: function (userDetails) { return request('/api/user/update', userDetails); },
    setDefaultAddress: function (defaultAddressId) { return request('/api/user/setDefaultDeliveryAddress', {
        defaultAddressId: defaultAddressId,
    }); },
    updatePreferences: function (language, currency) { return request('/api/user/updatePreferences', {
        language: language,
        currency: currency,
    }); },
};
export var addressApi = {
    create: function (address) { return request('/api/address/create', address); },
    update: function (address) { return request('/api/address/update', address); },
    delete: function (addressId) { return request('/api/address/delete', {
        addressId: addressId,
    }); },
};
export var hasValidDeliveryAddress = function (address) {
    return address && address.line1 && address.city && address.country && address.postalCode && address.fullName;
};
var mapRawUserDertailsToUserDetails = function (rud) {
    var phoneNumber = rud.phoneNumber, phoneRegionCode = rud.phoneRegionCode, companyPhoneNumber = rud.companyPhoneNumber, companyPhoneRegionCode = rud.companyPhoneRegionCode, companyName = rud.companyName, vatNumber = rud.vatNumber, rest = __rest(rud, ["phoneNumber", "phoneRegionCode", "companyPhoneNumber", "companyPhoneRegionCode", "companyName", "vatNumber"]);
    return __assign(__assign({}, rest), { phoneNumber: phoneNumber || '', phoneRegionCode: phoneRegionCode || '', companyName: companyName || '', companyPhoneNumber: companyPhoneNumber || '', companyPhoneRegionCode: companyPhoneRegionCode || '', vatNumber: vatNumber || '' });
};
export function onRefresh(_a) {
    var session, e_1, errorMsg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 6, , 9]);
                return [4 /*yield*/, call(authApi.refreshToken, payload)];
            case 1:
                session = _c.sent();
                if (!('error' in session)) return [3 /*break*/, 3];
                return [4 /*yield*/, put(refreshFailure('Invalid refresh token.'))];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                setSession(session);
                return [4 /*yield*/, put(refreshSuccess(session))];
            case 4:
                _c.sent();
                _c.label = 5;
            case 5: return [3 /*break*/, 9];
            case 6:
                e_1 = _c.sent();
                errorMsg = (_b = tryMapError(e_1)) !== null && _b !== void 0 ? _b : 'Session refresh failure.';
                return [4 /*yield*/, put(refreshFailure(errorMsg))];
            case 7:
                _c.sent();
                return [4 /*yield*/, put(logout())];
            case 8:
                _c.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function checkSession() {
    var session, userIdData, now, jwtPayload, newSession, newUserIdData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(getSession)];
            case 1:
                session = _a.sent();
                return [4 /*yield*/, call(getUser)];
            case 2:
                userIdData = _a.sent();
                if (!(!session || !userIdData)) return [3 /*break*/, 5];
                if (!(session || userIdData)) return [3 /*break*/, 4];
                return [4 /*yield*/, put(logout())];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/];
            case 5: return [4 /*yield*/, call(Date.now)];
            case 6:
                now = _a.sent();
                if (!isBefore(new Date(session.refreshTokenExpiration), now)) return [3 /*break*/, 8];
                return [4 /*yield*/, put(logout())];
            case 7:
                _a.sent();
                return [2 /*return*/];
            case 8:
                if (!isBefore(subSeconds(new Date(session.expiration), 10), now)) return [3 /*break*/, 13];
                return [4 /*yield*/, call(parseJwt, session.token)];
            case 9:
                jwtPayload = _a.sent();
                return [4 /*yield*/, call(onRefresh, refresh({
                        email: jwtPayload.email,
                        refreshToken: session.refreshToken,
                    }))];
            case 10:
                _a.sent();
                return [4 /*yield*/, call(getSession)];
            case 11:
                newSession = _a.sent();
                return [4 /*yield*/, call(getUser)];
            case 12:
                newUserIdData = _a.sent();
                return [2 /*return*/, {
                        session: newSession,
                        userIdData: newUserIdData,
                    }];
            case 13: return [2 /*return*/, {
                    session: session,
                    userIdData: userIdData,
                }];
        }
    });
}
export function onSessionKeep(_) {
    var session, now, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 9]);
                _a.label = 1;
            case 1:
                if (!true) return [3 /*break*/, 5];
                return [4 /*yield*/, call(checkSession)];
            case 2:
                session = ((_a.sent()) || {}).session;
                if (!session) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, call(Date.now)];
            case 3:
                now = _a.sent();
                return [4 /*yield*/, delay(differenceInMilliseconds(subSeconds(new Date(session.expiration), 10), now))];
            case 4:
                _a.sent();
                return [3 /*break*/, 1];
            case 5: return [3 /*break*/, 9];
            case 6:
                e_2 = _a.sent();
                console.error(e_2);
                return [4 /*yield*/, call(toast.error, 'You have been logged out')];
            case 7:
                _a.sent();
                return [4 /*yield*/, put(logout())];
            case 8:
                _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function onRestoreSession(_) {
    var _a, session, userIdData, userDetails, e_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 13, , 16]);
                return [4 /*yield*/, put(loadGlobalSettings())];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(checkSession)];
            case 2:
                _a = (_b.sent()) || {}, session = _a.session, userIdData = _a.userIdData;
                if (!(!session || !userIdData)) return [3 /*break*/, 5];
                return [4 /*yield*/, call(removeSession)];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(removeUser)];
            case 4:
                _b.sent();
                return [2 /*return*/];
            case 5: return [4 /*yield*/, put(loginSuccess(session, userIdData))];
            case 6:
                _b.sent();
                return [4 /*yield*/, call(userApi.getDetails)];
            case 7:
                userDetails = _b.sent();
                return [4 /*yield*/, put(userDetailsLoaded(mapRawUserDertailsToUserDetails(userDetails)))];
            case 8:
                _b.sent();
                return [4 /*yield*/, put(loadUserSettings())];
            case 9:
                _b.sent();
                return [4 /*yield*/, put(sessionKeep())];
            case 10:
                _b.sent();
                return [4 /*yield*/, put(reloadReservationFromCache())];
            case 11:
                _b.sent();
                return [4 /*yield*/, put(eventsActions.reloadEventDetails(null))];
            case 12:
                _b.sent();
                return [3 /*break*/, 16];
            case 13:
                e_3 = _b.sent();
                return [4 /*yield*/, put(logout())];
            case 14:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot load user details')];
            case 15:
                _b.sent();
                return [3 /*break*/, 16];
            case 16: return [2 /*return*/];
        }
    });
}
function handleLoginData(_a) {
    var jwtData, userIdData;
    var userData = _a.userData, session = __rest(_a, ["userData"]);
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                jwtData = parseJwt(session.token);
                userIdData = {
                    userId: jwtData.user_id,
                };
                setSession(session);
                return [4 /*yield*/, call(setUser, userIdData)];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(loadUserSettings())];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(userDetailsLoaded(mapRawUserDertailsToUserDetails(userData)))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(loginSuccess(session, userIdData))];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(sessionKeep())];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(assignReservationToUser())];
            case 6:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function onLogin(_a) {
    var errorHandler, loginResult, wasLoggedIn, redirect, e_4;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, put(loginStart())];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 11, , 13]);
                errorHandler = function (error) {
                    if (!error || !error.response) {
                        return Promise.reject(error);
                    }
                    if (error.response.status === 401) {
                        return Promise.reject(Error('We don\'t recognise that username and/or password. Please try again, or you can reset your password using the link below.'));
                    }
                    else if (error.response.status === 403) {
                        window.location.replace(routes.index());
                    }
                    return Promise.reject(error.response);
                };
                return [4 /*yield*/, call(authApi.login, payload, errorHandler)];
            case 3:
                loginResult = _b.sent();
                return [4 /*yield*/, call(handleLoginData, loginResult)];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(getCookie, USER_COOKIE_KEY)];
            case 5:
                wasLoggedIn = _b.sent();
                if (!!wasLoggedIn) return [3 /*break*/, 7];
                return [4 /*yield*/, call(setCookie, USER_COOKIE_KEY, uuid(), 999)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [4 /*yield*/, put(eventsActions.reloadEventDetails(null))];
            case 8:
                _b.sent();
                redirect = payload.redirect;
                if (!redirect) return [3 /*break*/, 10];
                return [4 /*yield*/, put(replace(redirect))];
            case 9:
                _b.sent();
                _b.label = 10;
            case 10: return [3 /*break*/, 13];
            case 11:
                e_4 = _b.sent();
                return [4 /*yield*/, put(loginFailure(get(e_4, 'message') || tryMapError(e_4) || 'Login failure'))];
            case 12:
                _b.sent();
                return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}
export function onExternalLogin(_a) {
    var errorHandler, loginResult, _b, _c, wasLoggedIn, redirect, e_5;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, put(loginStart())];
            case 1:
                _d.sent();
                _d.label = 2;
            case 2:
                _d.trys.push([2, 15, , 17]);
                errorHandler = function (error) {
                    if (!error || !error.response) {
                        return Promise.reject(error);
                    }
                    if (error.response.status === 401) {
                        return Promise.reject(Error("Your " + payload.provider + " user is not authorized to login."));
                    }
                    else if (error.response.status === 403) {
                        window.location.replace(routes.index());
                    }
                    return Promise.reject(error.response);
                };
                if (!(payload.provider === 'Facebook')) return [3 /*break*/, 4];
                return [4 /*yield*/, call(authApi.facebookLogin, payload, errorHandler)];
            case 3:
                _b = _d.sent();
                return [3 /*break*/, 8];
            case 4:
                if (!(payload.provider === 'Google')) return [3 /*break*/, 6];
                return [4 /*yield*/, call(authApi.googleLogin, payload, errorHandler)];
            case 5:
                _c = _d.sent();
                return [3 /*break*/, 7];
            case 6:
                _c = undefined;
                _d.label = 7;
            case 7:
                _b = _c;
                _d.label = 8;
            case 8:
                loginResult = _b;
                return [4 /*yield*/, call(handleLoginData, loginResult)];
            case 9:
                _d.sent();
                return [4 /*yield*/, call(getCookie, USER_COOKIE_KEY)];
            case 10:
                wasLoggedIn = _d.sent();
                if (!!wasLoggedIn) return [3 /*break*/, 12];
                return [4 /*yield*/, call(setCookie, USER_COOKIE_KEY, uuid(), 999)];
            case 11:
                _d.sent();
                _d.label = 12;
            case 12:
                redirect = payload.redirect;
                if (!redirect) return [3 /*break*/, 14];
                return [4 /*yield*/, put(replace(redirect))];
            case 13:
                _d.sent();
                _d.label = 14;
            case 14: return [3 /*break*/, 17];
            case 15:
                e_5 = _d.sent();
                return [4 /*yield*/, put(loginFailure(get(e_5, 'message') || "Login using " + payload.provider + " failed. Please try again later."))];
            case 16:
                _d.sent();
                return [3 /*break*/, 17];
            case 17: return [2 /*return*/];
        }
    });
}
export function onLogout() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(clearReservations())];
            case 1:
                _a.sent();
                return [4 /*yield*/, call(removeSession)];
            case 2:
                _a.sent();
                return [4 /*yield*/, call(removeUser)];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(sessionKeep())];
            case 4:
                _a.sent(); // stop session keeping loop
                return [4 /*yield*/, put(loginFailure(null))];
            case 5:
                _a.sent();
                return [4 /*yield*/, put(registerReset())];
            case 6:
                _a.sent();
                return [4 /*yield*/, put(clearStore())];
            case 7:
                _a.sent();
                return [4 /*yield*/, put(loadGlobalSettings())];
            case 8:
                _a.sent();
                return [4 /*yield*/, put(loadPromotedPerformers())];
            case 9:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function onUpdateUserDetails(_a) {
    var details, disableToasts, userDetails, e_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                details = payload.details, disableToasts = payload.disableToasts;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 7, , 11]);
                return [4 /*yield*/, call(userApi.updateDetails, details)];
            case 2:
                userDetails = _b.sent();
                return [4 /*yield*/, put(userDetailsLoaded(mapRawUserDertailsToUserDetails(userDetails)))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(updateUserDetailsSuccess())];
            case 4:
                _b.sent();
                if (!!disableToasts) return [3 /*break*/, 6];
                return [4 /*yield*/, call(toast.success, 'User details have been changed')];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [3 /*break*/, 11];
            case 7:
                e_6 = _b.sent();
                if (!!disableToasts) return [3 /*break*/, 9];
                return [4 /*yield*/, call(toast.error, 'Cannot update user details')];
            case 8:
                _b.sent();
                _b.label = 9;
            case 9: return [4 /*yield*/, put(updateUserDetailsFailure())];
            case 10:
                _b.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
export function onUpdateUserName(_a) {
    var fullName, disableToasts, details, useDefaultDeliveryAddressForBusiness, defaultBusinessAddress, defaultDeliveryAddress;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var payload = _a.payload;
    return __generator(this, function (_m) {
        switch (_m.label) {
            case 0:
                fullName = payload.fullName, disableToasts = payload.disableToasts;
                return [4 /*yield*/, select(selectUserDetails)];
            case 1:
                details = _m.sent();
                if (!details) {
                    return [2 /*return*/];
                }
                useDefaultDeliveryAddressForBusiness = details.useDefaultDeliveryAddressForBusiness, defaultBusinessAddress = details.defaultBusinessAddress, defaultDeliveryAddress = details.defaultDeliveryAddress;
                return [4 /*yield*/, put(updateUserDetails({
                        details: {
                            fullName: fullName !== null && fullName !== void 0 ? fullName : '',
                            phoneRegionCode: details.phoneRegionCode,
                            phoneNumber: details.phoneNumber,
                            isCompany: details.isCompany,
                            companyName: details.companyName,
                            companyPhoneNumber: details.companyPhoneNumber,
                            companyPhoneRegionCode: details.companyPhoneRegionCode,
                            vatNumber: details.vatNumber,
                            useDefaultDeliveryAddressForBusiness: useDefaultDeliveryAddressForBusiness,
                            line1: useDefaultDeliveryAddressForBusiness ? (_b = defaultDeliveryAddress === null || defaultDeliveryAddress === void 0 ? void 0 : defaultDeliveryAddress.line1) !== null && _b !== void 0 ? _b : '' : (_c = defaultBusinessAddress === null || defaultBusinessAddress === void 0 ? void 0 : defaultBusinessAddress.line1) !== null && _c !== void 0 ? _c : '',
                            line2: useDefaultDeliveryAddressForBusiness ? (_d = defaultDeliveryAddress === null || defaultDeliveryAddress === void 0 ? void 0 : defaultDeliveryAddress.line2) !== null && _d !== void 0 ? _d : '' : (_e = defaultBusinessAddress === null || defaultBusinessAddress === void 0 ? void 0 : defaultBusinessAddress.line2) !== null && _e !== void 0 ? _e : '',
                            city: useDefaultDeliveryAddressForBusiness ? (_f = defaultDeliveryAddress === null || defaultDeliveryAddress === void 0 ? void 0 : defaultDeliveryAddress.city) !== null && _f !== void 0 ? _f : '' : (_g = defaultBusinessAddress === null || defaultBusinessAddress === void 0 ? void 0 : defaultBusinessAddress.city) !== null && _g !== void 0 ? _g : '',
                            postalCode: useDefaultDeliveryAddressForBusiness ? (_h = defaultDeliveryAddress === null || defaultDeliveryAddress === void 0 ? void 0 : defaultDeliveryAddress.postalCode) !== null && _h !== void 0 ? _h : '' : (_j = defaultBusinessAddress === null || defaultBusinessAddress === void 0 ? void 0 : defaultBusinessAddress.postalCode) !== null && _j !== void 0 ? _j : '',
                            country: useDefaultDeliveryAddressForBusiness ? (_k = defaultDeliveryAddress === null || defaultDeliveryAddress === void 0 ? void 0 : defaultDeliveryAddress.country) !== null && _k !== void 0 ? _k : '' : (_l = defaultBusinessAddress === null || defaultBusinessAddress === void 0 ? void 0 : defaultBusinessAddress.country) !== null && _l !== void 0 ? _l : '',
                        },
                        disableToasts: disableToasts
                    }))];
            case 2:
                _m.sent();
                return [2 /*return*/];
        }
    });
}
export function onUpdatePhoneNumber(_a) {
    var phoneNumber, details, useDefaultDeliveryAddressForBusiness, defaultBusinessAddress, defaultDeliveryAddress;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var payload = _a.payload;
    return __generator(this, function (_m) {
        switch (_m.label) {
            case 0:
                phoneNumber = payload.phoneNumber;
                return [4 /*yield*/, select(selectUserDetails)];
            case 1:
                details = _m.sent();
                if (!details) {
                    return [2 /*return*/];
                }
                useDefaultDeliveryAddressForBusiness = details.useDefaultDeliveryAddressForBusiness, defaultBusinessAddress = details.defaultBusinessAddress, defaultDeliveryAddress = details.defaultDeliveryAddress;
                return [4 /*yield*/, put(updateUserDetails({
                        details: {
                            fullName: details.fullName,
                            phoneRegionCode: phoneNumber.regionCode,
                            phoneNumber: phoneNumber.significant,
                            isCompany: details.isCompany,
                            companyName: details.companyName,
                            companyPhoneNumber: details.companyPhoneNumber,
                            companyPhoneRegionCode: details.companyPhoneRegionCode,
                            vatNumber: details.vatNumber,
                            useDefaultDeliveryAddressForBusiness: useDefaultDeliveryAddressForBusiness,
                            line1: useDefaultDeliveryAddressForBusiness ? (_b = defaultDeliveryAddress === null || defaultDeliveryAddress === void 0 ? void 0 : defaultDeliveryAddress.line1) !== null && _b !== void 0 ? _b : '' : (_c = defaultBusinessAddress === null || defaultBusinessAddress === void 0 ? void 0 : defaultBusinessAddress.line1) !== null && _c !== void 0 ? _c : '',
                            line2: useDefaultDeliveryAddressForBusiness ? (_d = defaultDeliveryAddress === null || defaultDeliveryAddress === void 0 ? void 0 : defaultDeliveryAddress.line2) !== null && _d !== void 0 ? _d : '' : (_e = defaultBusinessAddress === null || defaultBusinessAddress === void 0 ? void 0 : defaultBusinessAddress.line2) !== null && _e !== void 0 ? _e : '',
                            city: useDefaultDeliveryAddressForBusiness ? (_f = defaultDeliveryAddress === null || defaultDeliveryAddress === void 0 ? void 0 : defaultDeliveryAddress.city) !== null && _f !== void 0 ? _f : '' : (_g = defaultBusinessAddress === null || defaultBusinessAddress === void 0 ? void 0 : defaultBusinessAddress.city) !== null && _g !== void 0 ? _g : '',
                            postalCode: useDefaultDeliveryAddressForBusiness ? (_h = defaultDeliveryAddress === null || defaultDeliveryAddress === void 0 ? void 0 : defaultDeliveryAddress.postalCode) !== null && _h !== void 0 ? _h : '' : (_j = defaultBusinessAddress === null || defaultBusinessAddress === void 0 ? void 0 : defaultBusinessAddress.postalCode) !== null && _j !== void 0 ? _j : '',
                            country: useDefaultDeliveryAddressForBusiness ? (_k = defaultDeliveryAddress === null || defaultDeliveryAddress === void 0 ? void 0 : defaultDeliveryAddress.country) !== null && _k !== void 0 ? _k : '' : (_l = defaultBusinessAddress === null || defaultBusinessAddress === void 0 ? void 0 : defaultBusinessAddress.country) !== null && _l !== void 0 ? _l : '',
                        }
                    }))];
            case 2:
                _m.sent();
                return [2 /*return*/];
        }
    });
}
export function onRequestChangeUserEmailAndDetails(_a) {
    var email, password, details, resolve, reject, refreshToken, e_7, msg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                email = payload.email, password = payload.password, details = payload.details, resolve = payload.resolve, reject = payload.reject;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 8, , 12]);
                return [4 /*yield*/, call(authApi.changeEmail, email, password)];
            case 2:
                _c.sent();
                return [4 /*yield*/, put(changeUserEmailAndDetailsSuccess(email))];
            case 3:
                _c.sent();
                return [4 /*yield*/, call(onUpdateUserDetails, updateUserDetails({ details: details }))];
            case 4:
                _c.sent();
                return [4 /*yield*/, select(selectSession)];
            case 5:
                refreshToken = (_c.sent()).refreshToken;
                return [4 /*yield*/, put(refresh({
                        email: email,
                        refreshToken: refreshToken,
                    }))];
            case 6:
                _c.sent();
                return [4 /*yield*/, call(resolve, undefined)];
            case 7:
                _c.sent();
                return [3 /*break*/, 12];
            case 8:
                e_7 = _c.sent();
                msg = (_b = tryMapError(e_7)) !== null && _b !== void 0 ? _b : 'Cannot update email, please check your password';
                return [4 /*yield*/, call(reject, msg)];
            case 9:
                _c.sent();
                return [4 /*yield*/, call(toast.error, msg)];
            case 10:
                _c.sent();
                return [4 /*yield*/, put(changeUserEmailAndDetailsFailure())];
            case 11:
                _c.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function onRequestChangeUserEmail(_a) {
    var email, password, resolve, reject, refreshToken, e_8, msg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                email = payload.email, password = payload.password, resolve = payload.resolve, reject = payload.reject;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 6, , 9]);
                return [4 /*yield*/, call(authApi.changeEmail, email, password)];
            case 2:
                _c.sent();
                return [4 /*yield*/, select(selectSession)];
            case 3:
                refreshToken = (_c.sent()).refreshToken;
                return [4 /*yield*/, put(refresh({
                        email: email,
                        refreshToken: refreshToken,
                    }))];
            case 4:
                _c.sent();
                return [4 /*yield*/, call(resolve, undefined)];
            case 5:
                _c.sent();
                return [3 /*break*/, 9];
            case 6:
                e_8 = _c.sent();
                msg = (_b = tryMapError(e_8)) !== null && _b !== void 0 ? _b : 'Cannot update email, please check your password';
                return [4 /*yield*/, call(reject, msg)];
            case 7:
                _c.sent();
                return [4 /*yield*/, call(toast.error, msg)];
            case 8:
                _c.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function onGetUserDetails() {
    var userDetails, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(userApi.getDetails)];
            case 1:
                userDetails = _a.sent();
                return [4 /*yield*/, put(userDetailsLoaded(mapRawUserDertailsToUserDetails(userDetails)))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_9 = _a.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot get user details')];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function onRequestResetUserPassword(_a) {
    var email, doNotRedirect, type, e_10, msg;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                email = payload.email, doNotRedirect = payload.doNotRedirect, type = payload.type;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 9]);
                return [4 /*yield*/, call(authApi.requestResetPassword, email, type)];
            case 2:
                _b.sent();
                if (!!doNotRedirect) return [3 /*break*/, 4];
                return [4 /*yield*/, put(replace(type === 'reset' ? routes.resetPasswordSent() : routes.setPasswordSent()))];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [4 /*yield*/, put(requestResetUserPasswordSuccess(email))];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6:
                e_10 = _b.sent();
                msg = getErrorMsg('Cannot request password reset', e_10);
                return [4 /*yield*/, call(toast.error, msg)];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(requestResetUserPasswordFailure())];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function onResetUserPassword(_a) {
    var email, password, confirmPassword, token, next, type, loginResult, verb, e_11, msg;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                email = payload.email, password = payload.password, confirmPassword = payload.confirmPassword, token = payload.token, next = payload.next, type = payload.type;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 7, , 9]);
                return [4 /*yield*/, call(authApi.resetPassword, email, token, password, confirmPassword)];
            case 2:
                loginResult = _c.sent();
                return [4 /*yield*/, put(replace(next !== null && next !== void 0 ? next : routes.index()))];
            case 3:
                _c.sent();
                return [4 /*yield*/, call(handleLoginData, loginResult)];
            case 4:
                _c.sent();
                verb = type === 'set' ? 'set' : 'changed';
                return [4 /*yield*/, call(toast.success, "Password has been " + verb)];
            case 5:
                _c.sent();
                return [4 /*yield*/, put(resetUserPasswordSuccess(email))];
            case 6:
                _c.sent();
                return [3 /*break*/, 9];
            case 7:
                e_11 = _c.sent();
                msg = (_b = tryMapError(e_11)) !== null && _b !== void 0 ? _b : 'Cannot update your password';
                return [4 /*yield*/, put(resetUserPasswordFailure([msg, '']))];
            case 8:
                _c.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function onCreateAddress(_a) {
    var address, redirect, userDetails, e_12, msg;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                address = payload.address, redirect = payload.redirect;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 8, , 11]);
                return [4 /*yield*/, call(addressApi.create, address)];
            case 2:
                userDetails = _b.sent();
                return [4 /*yield*/, put(userDetailsLoaded(mapRawUserDertailsToUserDetails(userDetails)))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(createAddressSuccess(mapRawUserDertailsToUserDetails(userDetails)))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.success, 'Address has been created')];
            case 5:
                _b.sent();
                if (!redirect) return [3 /*break*/, 7];
                return [4 /*yield*/, put(push(routes.userAddresses()))];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 11];
            case 8:
                e_12 = _b.sent();
                msg = getErrorMsg('Cannot create address', e_12);
                return [4 /*yield*/, call(toast.error, msg)];
            case 9:
                _b.sent();
                return [4 /*yield*/, put(createAddressFailure([msg]))];
            case 10:
                _b.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
export function onUpdateAddress(_a) {
    var address, updatedAddress, e_13, msg;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                address = payload.address;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 9]);
                return [4 /*yield*/, call(addressApi.update, address)];
            case 2:
                updatedAddress = _b.sent();
                return [4 /*yield*/, put(updateAddressSuccess(updatedAddress))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(toast.success, 'Address has been updated')];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(push(routes.userAddresses()))];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6:
                e_13 = _b.sent();
                msg = getErrorMsg('Cannot update address', e_13);
                return [4 /*yield*/, call(toast.error, msg)];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(updateAddressFailure([msg]))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function onSetDefaultAddress(_a) {
    var defaultAddressId, userDetails, e_14, msg;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                defaultAddressId = payload.defaultAddressId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 9]);
                return [4 /*yield*/, call(userApi.setDefaultAddress, defaultAddressId)];
            case 2:
                userDetails = _b.sent();
                return [4 /*yield*/, put(userDetailsLoaded(mapRawUserDertailsToUserDetails(userDetails)))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setDefaultAddressSuccess(mapRawUserDertailsToUserDetails(userDetails)))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.success, 'Default address has been set')];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6:
                e_14 = _b.sent();
                msg = getErrorMsg('Cannot set default address', e_14);
                return [4 /*yield*/, call(toast.error, msg)];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(setDefaultAddressFailure(msg))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function onUpdatePreferences(_a) {
    var language, currency, userDetails, e_15, msg;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                language = payload.language, currency = payload.currency;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 9]);
                return [4 /*yield*/, call(userApi.updatePreferences, language, currency)];
            case 2:
                userDetails = _b.sent();
                return [4 /*yield*/, put(userDetailsLoaded(mapRawUserDertailsToUserDetails(userDetails)))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(updatePreferencesSuccess(mapRawUserDertailsToUserDetails(userDetails)))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.success, 'Preferences has been updated')];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6:
                e_15 = _b.sent();
                msg = getErrorMsg('Cannot update preferences', e_15);
                return [4 /*yield*/, call(toast.error, msg)];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(updatePreferencesFailure(msg))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function onDeleteAddress(_a) {
    var addressId, userDetails, e_16, msg;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                addressId = payload.addressId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 9]);
                return [4 /*yield*/, call(addressApi.delete, addressId)];
            case 2:
                userDetails = _b.sent();
                return [4 /*yield*/, put(userDetailsLoaded(mapRawUserDertailsToUserDetails(userDetails)))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(deleteAddressSuccess(addressId))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.success, 'Address has been deleted')];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6:
                e_16 = _b.sent();
                msg = getErrorMsg('Cannot delete address', e_16);
                return [4 /*yield*/, call(toast.error, msg)];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(deleteAddressFailure([msg]))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function onVerifyResetPasswordToken(_a) {
    var email, token, e_17;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                email = payload.email, token = payload.token;
                if (!!token) return [3 /*break*/, 3];
                return [4 /*yield*/, put(replace(routes.index()))];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'The page you want to visit is not available')];
            case 2:
                _b.sent();
                return [2 /*return*/];
            case 3:
                _b.trys.push([3, 5, , 8]);
                return [4 /*yield*/, call(authApi.verifyResetPasswordToken, email, token)];
            case 4:
                _b.sent();
                return [3 /*break*/, 8];
            case 5:
                e_17 = _b.sent();
                return [4 /*yield*/, put(replace(routes.index()))];
            case 6:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'You have already reset your password. If you need to do so again, please request a new secure password reset link via the Login page.')];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function userSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(sessionKeep.getType(), onSessionKeep)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(restoreSession.getType(), onRestoreSession)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(login.getType(), onLogin)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(externalLogin.getType(), onExternalLogin)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(logout.getType(), onLogout)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(refresh.getType(), onRefresh)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateUserDetails.getType(), onUpdateUserDetails)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateUserName.getType(), onUpdateUserName)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeLatest(updatePhoneNumber.getType(), onUpdatePhoneNumber)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeLatest(changeUserEmailAndDetails.getType(), onRequestChangeUserEmailAndDetails)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeLatest(changeUserEmail.getType(), onRequestChangeUserEmail)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeLatest(getUserDetails.getType(), onGetUserDetails)];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeLatest(requestResetUserPassword.getType(), onRequestResetUserPassword)];
            case 13:
                _a.sent();
                return [4 /*yield*/, takeLatest(resetUserPassword.getType(), onResetUserPassword)];
            case 14:
                _a.sent();
                return [4 /*yield*/, takeLatest(createAddress.getType(), onCreateAddress)];
            case 15:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateAddress.getType(), onUpdateAddress)];
            case 16:
                _a.sent();
                return [4 /*yield*/, takeLatest(setDefaultAddress.getType(), onSetDefaultAddress)];
            case 17:
                _a.sent();
                return [4 /*yield*/, takeLatest(updatePreferences.getType(), onUpdatePreferences)];
            case 18:
                _a.sent();
                return [4 /*yield*/, takeLatest(deleteAddress.getType(), onDeleteAddress)];
            case 19:
                _a.sent();
                return [4 /*yield*/, takeLatest(verifyResetPasswordToken.getType(), onVerifyResetPasswordToken)];
            case 20:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
