var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var _a;
import { getExpiryDateRange } from '@containers/ListingQuickEntry/components/basic';
import { actions as eventsActions, createEventLinkData, eventsApi, hasReservedSeating, rawEventToEvent } from '@logic/events';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import without from 'lodash/without';
import React from 'react';
import { toast } from 'react-toastify';
import { createAction, createReducer } from 'redux-act';
import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { routes } from 'src/client/routing/routes';
import isString from 'lodash/isString';
import { actions as cardsActions, onCreateCard } from './cards';
import { rawEventSummaryToEventSummary } from './events';
import { createSlug } from './helpers/location';
import request from './helpers/request';
import { error as validationError, isRequired, success } from './helpers/validators';
import { isSellSeatingInformation, normalizeBuySeatingInfo, normalizeSellSeatingInfo, seatingPlanToSections, anySection, } from './seatingInfo';
import { getMultipleFileImg } from './uploadImageFiles';
import { selectSettings, selectUserSettings } from './userSettings';
import uuid from 'uuid/v1';
import { tryMapError } from './errorCodeMapper';
export var ListingsFilter;
(function (ListingsFilter) {
    ListingsFilter["TicketType"] = "Ticket type";
    ListingsFilter["ListingStatus"] = "Listing status";
    ListingsFilter["ListingType"] = "Listing type";
})(ListingsFilter || (ListingsFilter = {}));
export var ticketTypeDescriptionMapping = {
    eTicket: 'e-Ticket',
    paper: 'Paper ticket',
    mobileTransfer: 'Mobile Transfer',
    ticketmaster: 'Ticketmaster Mobile Transfer',
    qrCode: 'Mobile QR Code',
    any: 'Any',
};
export var ticketTypeSimplifiedMapping = {
    eTicket: 'e-Ticket',
    paper: 'Paper',
    mobileTransfer: 'Mobile',
    ticketmaster: 'Mobile',
    qrCode: 'Mobile',
    any: 'Any',
};
export function getTransactionSide(ticketType) {
    return ticketType === 'sell' ? 'buy' : 'sell';
}
export var restrictionsNames = {
    restrictedView: 'Restricted / limited view',
    possibleObstruction: 'Possible obstruction',
    sideOrRearView: 'Rear / side view',
    restrictedLegRoom: 'Restricted legroom',
    concessionTicketChild: 'Concession ticket - child',
    concessionTicketStudent: 'Concession ticket - student',
    concessionTicketSeniorCitizen: 'Concession ticket - senior citizen',
    wheelchairUserOnly: 'Wheelchair user only',
    childTicket: 'Child ticket',
};
export var getSelectedRestrictions = function (restrictions) { return Object.keys(restrictions).filter(function (k) { return restrictions[k]; }); };
export var hasRestrictions = function (restrictions) { return getSelectedRestrictions(restrictions).length > 0; };
export var stringifyRestrictions = function (restrictions) {
    return Object.entries(restrictions)
        .filter(function (_a) {
        var _b = __read(_a, 2), v = _b[1];
        return !!v;
    })
        .map(function (k) { return restrictionsNames[k[0]]; })
        .join(', ');
};
export var mapPurchaseToListing = function (purchase, event, userSettings) {
    var eventInfo = mapEventToListingEventInfo(event);
    return {
        id: uuid(),
        isNew: true,
        systemStatus: 'active',
        userStatus: 'active',
        faceValue: purchase.faceValue,
        availableQuantity: purchase.quantity,
        ticketType: purchase.ticketType,
        totalQuantity: purchase.quantity,
        advancedFeatures: {
            icebergEnabled: false,
            icebergQuantity: undefined,
            autoChiselEnabled: false,
            autoChiselMinPrice: undefined,
            autoChiselRestrictions: '',
            ghostEnabled: false,
            watchOnlyEnabled: false,
        },
        restrictions: purchase.restrictions,
        seatingInformation: {
            venueId: event.venue.id,
            category: {
                name: purchase.seatingInfo.category,
                section: purchase.seatingInfo.section,
            },
            row: purchase.seatingInfo.row ? purchase.seatingInfo.row : null,
            startingSeat: purchase.seatingInfo.startingSeat,
        },
        event: eventInfo,
        ticketSplit: 'all',
        sellCurrency: event.currency || 'GBP',
        isEventOrganiser: false,
        expiryDate: getExpiryDateRange(eventInfo, userSettings, undefined, false).after,
        linkedTransactionId: purchase.transactionId
    };
};
export var mapEventToListingEventInfo = function (lei) {
    return {
        id: lei.id,
        eventSlug: createEventLinkData(lei.name, lei.eventDate, lei.venue.address.city, lei.id),
        name: lei.name,
        currency: lei.currency,
        venueName: lei.venue.name,
        venueCity: lei.venue.address.city,
        venueId: lei.venue.id,
        date: lei.eventDate,
        timezone: lei.timezone,
        seatingPlan: lei.seatingPlan,
        notes: lei.notes,
        restrictions: lei.restrictions,
        ticketTypes: lei.venue.ticketTypes,
        performers: lei.performers.map(function (p) { return p.name; }),
    };
};
export var isFullSellListing = function (draft) {
    return without(Object.keys(draft), 'isNew', 'niceId', 'ownedByMe', 'soldQuantity', 'isRestricted')
        .every(function (key) {
        return draft[key] != null;
    });
};
export var initialRestrictions = {
    restrictedView: false,
    sideOrRearView: false,
    possibleObstruction: false,
    restrictedLegRoom: false,
    childTicket: false,
    concessionTicketChild: false,
    concessionTicketStudent: false,
    concessionTicketSeniorCitizen: false,
    wheelchairUserOnly: false,
};
export var loadUserListings = createAction('load listings for user');
export var silentLoadUserListings = createAction('silent load listings for user');
export var loadUserListingsSuccess = createAction('fetching user listings succeed', function (listings) { return ({
    listings: listings,
}); });
export var loadUserListingsFailure = createAction('fetching user listings failed', function (error) { return ({
    error: error,
}); });
export var publishListings = createAction('create multi-listings', function (multiListingDto, callback) { return ({
    multiListingDto: multiListingDto,
    callback: callback,
}); });
export var publishListingsSuccess = createAction('listings save succeed', function (listings) { return ({
    listings: listings,
}); });
export var publishListingsFailure = createAction('listings save failed', function (error) { return ({
    error: error,
}); });
export var publishSellListing = createAction('create or update sell listing', function (listing, shouldLoadEventDetails, redirect) { return ({
    listing: listing,
    shouldLoadEventDetails: shouldLoadEventDetails,
    redirect: redirect,
}); });
export var publishSellListingSuccess = createAction('sell listing save succeed');
export var publishSellListingFailure = createAction('sell listing save failed', function (error) { return ({
    error: error,
}); });
export var publishBuyListing = createAction('create or update buy listing', function (listing, stripe, element, createCardParams, shouldLoadEventDetails, redirect) { return ({
    listing: listing,
    element: element,
    redirect: redirect,
    shouldLoadEventDetails: shouldLoadEventDetails,
    stripe: stripe,
    createCardParams: createCardParams,
}); });
export var publishBuyListingSuccess = createAction('buy listing save succeed');
export var publishBuyListingFailure = createAction('buy listing save failed', function (error) { return ({
    error: error,
}); });
export var loadSellListingDetails = createAction('load sell listing details', function (id) { return ({
    id: id,
}); });
export var loadSellListingDetailsSuccess = createAction('fetching sell listing details succeed', function (listing) { return ({
    listing: listing,
}); });
export var convertPurchaseToSellListing = createAction('convert purchase to sell listing', function (purchase) { return ({
    purchase: purchase,
}); });
export var loadSellListingDetailsFailure = createAction('fetching sell listing details failure', function (error) { return ({
    error: error,
}); });
export var clearSellListing = createAction('clear sell listing');
export var loadBuyListingDetails = createAction('load buy listing details', function (id) { return ({
    id: id,
}); });
export var loadBuyListingDetailsSuccess = createAction('fetching buy listing details succeed', function (listing) { return ({
    listing: listing,
}); });
export var loadBuyListingDetailsFailure = createAction('fetching buy listing details failure', function (error) { return ({
    error: error,
}); });
export var updateSellListingSnapshot = createAction('update sell listing snapshot', function (listing) { return ({
    listing: listing,
}); });
export var updateSellListingSnapshotWithTickets = createAction('update sell listing snapshot with ticketInfo', function (listing) { return ({
    listing: listing,
}); });
export var clearBuyListing = createAction('clear buy listing');
export var updateListingDetailsQuantity = createAction('update total quantity of sell listing (listing details)', function (id, quantityDiff, listingSnapshot) { return ({
    id: id,
    quantityDiff: quantityDiff,
    listingSnapshot: listingSnapshot
}); });
export var updateUserListingQuantity = createAction('update total quantity of sell listing (user listing)', function (id, quantityDiff) { return ({
    id: id,
    quantityDiff: quantityDiff
}); });
export var cancelListing = createAction('cancel listing', function (id, type) { return ({
    id: id,
    type: type,
}); });
export var cancelListingSuccess = createAction('cancel listing success');
export var cancelListingFailure = createAction('cancel listing failure');
export var patchSellListing = createAction('patch sell listing', function (id, patch, redirect) { return ({
    id: id,
    patch: patch,
    redirect: redirect,
}); });
export var patchSellListingSuccess = createAction('patch sell listing success', function (id) { return ({
    id: id,
}); });
export var patchSellListingFailure = createAction('patch sell listing failure', function (error) { return ({
    error: error,
}); });
export var patchBuyListing = createAction('patch buy listing', function (id, patch, redirect) { return ({
    id: id,
    patch: patch,
    redirect: redirect,
}); });
export var patchBuyListingSuccess = createAction('patch buy listing success', function (id) { return ({
    id: id,
}); });
export var patchBuyListingFailure = createAction('patch buy listing failure', function (error) { return ({
    error: error,
}); });
export var changePinnedRows = createAction('change pinned rows', function (pinnedRows) { return ({ pinnedRows: pinnedRows }); });
export var addPinnedRow = createAction('add pinned row', function (pinnedRow) { return ({ pinnedRow: pinnedRow }); });
var mapFullSellListingToUserListing = function (listing) {
    if (listing.isNew) {
        return null;
    }
    var id = listing.id, niceId = listing.niceId, event = listing.event, userStatus = listing.userStatus, systemStatus = listing.systemStatus, seatingInformation = listing.seatingInformation, ticketType = listing.ticketType, availableQuantity = listing.availableQuantity, totalQuantity = listing.totalQuantity, soldQuantity = listing.soldQuantity, faceValue = listing.faceValue, expiryDate = listing.expiryDate, adminExpiryDate = listing.adminExpiryDate, sellCurrency = listing.sellCurrency, sellPrice = listing.sellPrice, _a = listing.advancedFeatures, ghostEnabled = _a.ghostEnabled, watchOnlyEnabled = _a.watchOnlyEnabled, ticketsInfo = listing.ticketsInfo, linkedTransactionId = listing.linkedTransactionId;
    return {
        id: id,
        niceId: niceId || 0,
        event: __assign(__assign({}, event), { keywords: [], seatingPlan: event.seatingPlan }),
        hasTicketsAssigned: (ticketsInfo !== null && ticketsInfo !== void 0 ? ticketsInfo : []).slice(soldQuantity !== null && soldQuantity !== void 0 ? soldQuantity : 0).filter(function (t) { return !isEmpty(t); }).length > 0,
        userStatus: userStatus,
        isGhost: ghostEnabled,
        isWatchOnly: watchOnlyEnabled,
        systemStatus: systemStatus,
        seatingInfo: seatingInformation,
        ticketType: ticketType,
        listingType: 'sell',
        availableQuantity: availableQuantity,
        totalQuantity: totalQuantity,
        expiryDate: expiryDate,
        adminExpiryDate: adminExpiryDate,
        soldOrBoughtQuantity: soldQuantity || 0,
        price: {
            value: sellPrice,
            currency: sellCurrency,
        },
        faceValue: {
            value: faceValue,
            currency: sellCurrency,
        },
        linkedTransactionId: linkedTransactionId
    };
};
var mapFullBuyListingToUserListing = function (listing) {
    if (listing.isNew) {
        return null;
    }
    var id = listing.id, niceId = listing.niceId, event = listing.event, userStatus = listing.userStatus, systemStatus = listing.systemStatus, seatingInformation = listing.seatingInformation, ticketType = listing.ticketType, availableQuantity = listing.availableQuantity, totalQuantity = listing.totalQuantity, boughtQuantity = listing.boughtQuantity, minimumFaceValue = listing.minimumFaceValue, buyCurrency = listing.buyCurrency, expiryDate = listing.expiryDate, adminExpiryDate = listing.adminExpiryDate, buyPrice = listing.buyPrice, _a = listing.advancedFeatures, ghostEnabled = _a.ghostEnabled, watchOnlyEnabled = _a.watchOnlyEnabled;
    return {
        id: id,
        niceId: niceId || 0,
        event: __assign(__assign({}, event), { keywords: [], seatingPlan: event.seatingPlan }),
        hasTicketsAssigned: false,
        userStatus: userStatus,
        isGhost: ghostEnabled,
        isWatchOnly: watchOnlyEnabled,
        systemStatus: systemStatus,
        seatingInfo: seatingInformation,
        ticketType: ticketType,
        listingType: 'buy',
        availableQuantity: availableQuantity,
        expiryDate: new Date(expiryDate),
        adminExpiryDate: adminExpiryDate,
        totalQuantity: totalQuantity,
        soldOrBoughtQuantity: boughtQuantity || 0,
        price: {
            value: buyPrice,
            currency: buyCurrency,
        },
        faceValue: {
            value: minimumFaceValue,
            currency: buyCurrency,
        },
    };
};
var initialState = {
    isLoading: true,
    userListings: [],
    isLoadingSellListing: true,
    sellListing: null,
    sellListingSnapshot: null,
    isLoadingBuyListing: true,
    buyListing: null,
    pinnedRows: [],
};
var addOrUpdateListing = function (listings, listing) {
    if (listing == null) {
        return listings;
    }
    if (listings.find(function (l) { return l.id === listing.id; })) {
        return listings.map(function (l) { return l.id === listing.id ? listing : l; });
    }
    return listings.concat([listing]);
};
export var listingsReducer = createReducer((_a = {},
    _a[loadUserListings.getType()] = function (state) { return (__assign(__assign({}, state), { isLoading: true })); },
    _a[loadUserListingsSuccess.getType()] = function (state, _a) {
        var listings = _a.listings;
        return (__assign(__assign({}, state), { isLoading: false, userListings: listings }));
    },
    _a[loadUserListingsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoading: false, userListings: [] })); },
    _a[publishSellListing.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingSellListing: true })); },
    _a[publishSellListingSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingSellListing: false })); },
    _a[publishListingsSuccess.getType()] = function (state, _a) {
        var listings = _a.listings;
        return (__assign(__assign({}, state), { userListings: state.userListings.concat(listings) }));
    },
    _a[publishSellListingFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingSellListing: false })); },
    _a[publishBuyListing.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingBuyListing: true })); },
    _a[publishBuyListingSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingBuyListing: false })); },
    _a[publishBuyListingFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingBuyListing: false })); },
    _a[loadSellListingDetails.getType()] = function (state) { return (__assign(__assign({}, state), { sellListing: null, isLoadingSellListing: true })); },
    _a[loadSellListingDetailsSuccess.getType()] = function (state, _a) {
        var listing = _a.listing;
        return (__assign(__assign({}, state), { sellListing: listing, sellListingSnapshot: listing, isLoadingSellListing: false, userListings: addOrUpdateListing(state.userListings, mapFullSellListingToUserListing(listing)) }));
    },
    _a[loadSellListingDetailsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingSellListing: false })); },
    _a[clearSellListing.getType()] = function (state) { return (__assign(__assign({}, state), { sellListing: null, sellListingSnapshot: null })); },
    _a[updateSellListingSnapshot.getType()] = function (state, _a) {
        var listing = _a.listing;
        return (__assign(__assign({}, state), { sellListingSnapshot: listing }));
    },
    _a[loadBuyListingDetails.getType()] = function (state) { return (__assign(__assign({}, state), { buyListing: null, isLoadingBuyListing: true })); },
    _a[loadBuyListingDetailsSuccess.getType()] = function (state, _a) {
        var listing = _a.listing;
        return (__assign(__assign({}, state), { buyListing: listing, isLoadingBuyListing: false, userListings: addOrUpdateListing(state.userListings, mapFullBuyListingToUserListing(listing)) }));
    },
    _a[loadBuyListingDetailsFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoadingBuyListing: false })); },
    _a[clearBuyListing.getType()] = function (state) { return (__assign(__assign({}, state), { buyListing: null })); },
    _a[patchSellListing.getType()] = function (state) { return (__assign(__assign({}, state), { isLoading: true })); },
    _a[patchSellListingSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { isLoading: false })); },
    _a[patchSellListingFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoading: false })); },
    _a[patchBuyListing.getType()] = function (state) { return (__assign(__assign({}, state), { isLoading: true })); },
    _a[patchBuyListingSuccess.getType()] = function (state) { return (__assign(__assign({}, state), { isLoading: false })); },
    _a[patchBuyListingFailure.getType()] = function (state) { return (__assign(__assign({}, state), { isLoading: false })); },
    _a[changePinnedRows.getType()] = function (state, _a) {
        var pinnedRows = _a.pinnedRows;
        return (__assign(__assign({}, state), { pinnedRows: pinnedRows }));
    },
    _a[addPinnedRow.getType()] = function (state, _a) {
        var pinnedRow = _a.pinnedRow;
        return (__assign(__assign({}, state), { pinnedRows: __spreadArray(__spreadArray([], __read(state.pinnedRows)), [pinnedRow]) }));
    },
    _a), initialState);
export var isCancelled = function (data) {
    return !!data && (data.userStatus === 'cancelled' || data.systemStatus === 'cancelled');
};
export var rawUserListingToUserListing = function (listing) {
    var event = listing.event, listingCurrency = listing.listingCurrency, listingPrice = listing.listingPrice, faceValue = listing.faceValue, expiryDate = listing.expiryDate, rest = __rest(listing, ["event", "listingCurrency", "listingPrice", "faceValue", "expiryDate"]);
    return __assign(__assign({}, rest), { expiryDate: new Date(expiryDate), event: rawEventSummaryToEventSummary(event), price: {
            value: listingPrice,
            currency: listingCurrency,
        }, faceValue: {
            value: faceValue,
            currency: listingCurrency,
        } });
};
export var rawBuyListingToBuyListing = function (listing) {
    return __assign(__assign({}, listing), { expiryDate: new Date(listing.expiryDate), event: __assign(__assign({}, listing.event), { date: new Date(listing.event.date), currency: listing.event.currency // TODO: do safe conversion
         }), ticketType: listing.ticketType, userStatus: listing.userStatus, systemStatus: listing.systemStatus, buyCurrency: listing.buyCurrency, faceValueCurrency: listing.faceValueCurrency });
};
export var rawSellListingToSellListing = function (listing) {
    return __assign(__assign({}, listing), { expiryDate: new Date(listing.expiryDate), event: __assign(__assign({}, listing.event), { date: new Date(listing.event.date), currency: listing.event.currency // TODO: do safe conversion
         }), ticketsInfo: listing.ticketsInfo });
};
export var listingsApi = {
    getListingsForUser: function () { return request('/api/listings/user/0/0'); },
    publishListings: function (listings) { return request('/api/listings/create', listings); },
    publishSellListing: function (listing) { return request("/api/listings/sell/" + (listing.isNew ? 'create' : 'update'), listing); },
    publishBuyListing: function (listing) { return request("/api/listings/buy/" + (listing.isNew ? 'create' : 'update'), listing); },
    getSellListingById: function (id) { return request("/api/listings/sell/get/" + id); },
    getBuyListingById: function (id) { return request("/api/listings/buy/get/" + id); },
    getSimplifiedSellListingById: function (id, reservedQuantity) { return request("/api/listings/sell/getsimplified/" + id + "?reservedQuantity=" + reservedQuantity); },
    getSimplifiedBuyListingById: function (id) { return request("/api/listings/buy/getsimplified/" + id); },
    cancelSellListing: function (id) { return request("/api/listings/sell/delete/" + id, {}); },
    cancelBuyListing: function (id) { return request("/api/listings/buy/delete/" + id, {}); },
};
export function onLoadUserListings(_) {
    var results, listings, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(listingsApi.getListingsForUser)];
            case 1:
                results = _a.sent();
                listings = results.results.map(rawUserListingToUserListing);
                return [4 /*yield*/, put(loadUserListingsSuccess(orderBy(listings, [function (l) { return l.event.name; }, function (l) { return l.niceId; }], ['asc', 'desc'])))];
            case 2:
                _a.sent();
                return [3 /*break*/, 6];
            case 3:
                e_1 = _a.sent();
                return [4 /*yield*/, put(loadUserListingsFailure(e_1))];
            case 4:
                _a.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot fetch user listings')];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onPublishListings(_a) {
    var multiListingDto, callback, published, newestUserListings, e_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                multiListingDto = payload.multiListingDto, callback = payload.callback;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 8]);
                return [4 /*yield*/, call(listingsApi.publishListings, multiListingDto)];
            case 2:
                published = _b.sent();
                newestUserListings = __spreadArray(__spreadArray([], __read(published.sellListings.map(function (listing) { return mapFullSellListingToUserListing(rawSellListingToSellListing(listing)); }))), __read(published.buyListings.map(function (listing) { return mapFullBuyListingToUserListing(rawBuyListingToBuyListing(listing)); })));
                return [4 /*yield*/, put(publishListingsSuccess(newestUserListings))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(toast.success, 'Listings published')];
            case 4:
                _b.sent();
                callback.successCallback();
                return [3 /*break*/, 8];
            case 5:
                e_2 = _b.sent();
                callback.errorCallback();
                return [4 /*yield*/, put(publishListingsFailure(e_2))];
            case 6:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Publishing listings failed!')];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onUpdateSellListingSnapshotWithTickets(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(payload.listing && payload.listing.ticketsInfo)) return [3 /*break*/, 2];
                return [4 /*yield*/, put(getMultipleFileImg([payload.listing.id], payload.listing.ticketsInfo))];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2: return [4 /*yield*/, put(updateSellListingSnapshot(payload.listing))];
            case 3:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function onPublishSellListing(_a) {
    var listing, shouldLoadEventDetails, redirect, published, e_3, message;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 9, , 12]);
                listing = payload.listing, shouldLoadEventDetails = payload.shouldLoadEventDetails, redirect = payload.redirect;
                return [4 /*yield*/, call(listingsApi.publishSellListing, __assign(__assign({}, listing), { eventId: listing.event.id }))];
            case 1:
                published = _c.sent();
                return [4 /*yield*/, put(publishSellListingSuccess())];
            case 2:
                _c.sent();
                return [4 /*yield*/, put(getMultipleFileImg([listing.id], published.ticketsInfo))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(loadSellListingDetailsSuccess(rawSellListingToSellListing(published)))];
            case 4:
                _c.sent();
                if (!shouldLoadEventDetails) return [3 /*break*/, 6];
                return [4 /*yield*/, put(eventsActions.loadEventDetails.request({ eventId: listing.event.id }))];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6: return [4 /*yield*/, call(redirect, published.id)];
            case 7:
                _c.sent();
                return [4 /*yield*/, call(toast.success, "Listing " + (listing.userStatus === 'active' ? 'published' : 'saved'))];
            case 8:
                _c.sent();
                return [3 /*break*/, 12];
            case 9:
                e_3 = _c.sent();
                return [4 /*yield*/, put(publishSellListingFailure(e_3))];
            case 10:
                _c.sent();
                message = (_b = tryMapError(e_3)) !== null && _b !== void 0 ? _b : 'Your listing has not been saved!';
                return [4 /*yield*/, call(toast.error, message)];
            case 11:
                _c.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function onPublishBuyListing(_a) {
    var listing, redirect, stripe, element, createCardParams, shouldLoadEventDetails, clientSecret, cardCreationSuccess, published, e_4, message;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 12, , 15]);
                listing = payload.listing, redirect = payload.redirect, stripe = payload.stripe, element = payload.element, createCardParams = payload.createCardParams, shouldLoadEventDetails = payload.shouldLoadEventDetails;
                return [4 /*yield*/, select(function (gs) { return gs.cards.clientSecret; })];
            case 1:
                clientSecret = _c.sent();
                if (!(stripe && createCardParams && element)) return [3 /*break*/, 4];
                return [4 /*yield*/, call(onCreateCard, cardsActions.createCard.request({
                        stripe: stripe,
                        element: element,
                        card: createCardParams,
                        asDefault: false,
                        redirect: false,
                        clientSecret: clientSecret
                    }))];
            case 2:
                cardCreationSuccess = _c.sent();
                if (!!cardCreationSuccess) return [3 /*break*/, 4];
                return [4 /*yield*/, put(publishBuyListingFailure('Cannot create card'))];
            case 3:
                _c.sent();
                return [2 /*return*/];
            case 4: return [4 /*yield*/, call(listingsApi.publishBuyListing, __assign(__assign({}, listing), { eventId: listing.event.id }))];
            case 5:
                published = _c.sent();
                return [4 /*yield*/, put(publishBuyListingSuccess())];
            case 6:
                _c.sent();
                return [4 /*yield*/, put(loadBuyListingDetailsSuccess(rawBuyListingToBuyListing(published)))];
            case 7:
                _c.sent();
                if (!shouldLoadEventDetails) return [3 /*break*/, 9];
                return [4 /*yield*/, put(eventsActions.loadEventDetails.request({ eventId: listing.event.id }))];
            case 8:
                _c.sent();
                _c.label = 9;
            case 9: return [4 /*yield*/, call(redirect, published.id)];
            case 10:
                _c.sent();
                return [4 /*yield*/, call(toast.success, "Listing " + (listing.userStatus === 'active' ? 'published' : 'saved'))];
            case 11:
                _c.sent();
                return [3 /*break*/, 15];
            case 12:
                e_4 = _c.sent();
                return [4 /*yield*/, put(publishBuyListingFailure(e_4))];
            case 13:
                _c.sent();
                message = (_b = tryMapError(e_4)) !== null && _b !== void 0 ? _b : 'Your listing has not been saved!';
                return [4 /*yield*/, call(toast.error, message)];
            case 14:
                _c.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
export function onLoadSellListingDetails(_a) {
    var id, listing, e_5;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                id = payload.id;
                return [4 /*yield*/, call(listingsApi.getSellListingById, id)];
            case 1:
                listing = _b.sent();
                return [4 /*yield*/, put(loadSellListingDetailsSuccess(rawSellListingToSellListing(listing)))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_5 = _b.sent();
                return [4 /*yield*/, put(loadSellListingDetailsFailure(e_5))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot fetch specified listing')];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onConvertPurchaseToSellListing(_a) {
    var purchase, eventDetails, event, userSettings, listing, e_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 8]);
                purchase = payload.purchase;
                return [4 /*yield*/, call(eventsApi.getWithListings, purchase.event.id)];
            case 1:
                eventDetails = _b.sent();
                event = rawEventToEvent(eventDetails.event);
                return [4 /*yield*/, select(selectSettings)];
            case 2:
                userSettings = _b.sent();
                listing = mapPurchaseToListing(purchase, event, userSettings);
                return [4 /*yield*/, put(loadSellListingDetailsSuccess(listing))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(push(routes.listingFromPage('sell', createSlug(event.id, event.name), listing.id, 'purchases', purchase.transactionId)))];
            case 4:
                _b.sent();
                return [3 /*break*/, 8];
            case 5:
                e_6 = _b.sent();
                return [4 /*yield*/, put(loadSellListingDetailsFailure(e_6))];
            case 6:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot convert specified purchase to sell listing')];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function onLoadBuyListingDetails(_a) {
    var id, listing, e_7;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                id = payload.id;
                return [4 /*yield*/, call(listingsApi.getBuyListingById, id)];
            case 1:
                listing = _b.sent();
                return [4 /*yield*/, put(loadBuyListingDetailsSuccess(rawBuyListingToBuyListing(listing)))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_7 = _b.sent();
                return [4 /*yield*/, put(loadBuyListingDetailsFailure(e_7))];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot fetch specified listing')];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export var sectionValidator = function (section, event) {
    if (section) {
        var sections = seatingPlanToSections(event.seatingPlan, true);
        return sections.find(function (s) { return s.section === section.section && s.category === section.category; }) || section.category === 'Any' ? success : validationError('Invalid section');
    }
    return isRequired(section);
};
export var reservedSectionValidator = function (section, row, startingSeat, event) {
    return hasReservedSeating(section, event.seatingPlan) ? row && startingSeat : true;
};
export function isListingValidListing(listing) {
    var seatingInfo, section;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                seatingInfo = isSellSeatingInformation(listing.seatingInformation) ? normalizeSellSeatingInfo(listing.seatingInformation, null) : normalizeBuySeatingInfo(listing.seatingInformation)[0];
                section = seatingInfo.matchAny
                    ? anySection
                    : {
                        category: seatingInfo.category,
                        section: seatingInfo.section
                    };
                if (listing.userStatus !== 'active') {
                    return [2 /*return*/, true];
                }
                if (!(sectionValidator(section, listing.event) !== success)) return [3 /*break*/, 2];
                return [4 /*yield*/, call(toast.error, (React.createElement("div", null,
                        "Your listing has not been saved!",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Please update the Section in order to enable changes to this listing.")))];
            case 1:
                _a.sent();
                return [2 /*return*/, false];
            case 2:
                if (!(isSellSeatingInformation(listing.seatingInformation) && !reservedSectionValidator(section, listing.seatingInformation.row, listing.seatingInformation.startingSeat, listing.event))) return [3 /*break*/, 4];
                return [4 /*yield*/, call(toast.error, (React.createElement("div", null,
                        "Your listing has not been saved!",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Please update the Row and Starting Seat values in order to activate this listing.")))];
            case 3:
                _a.sent();
                return [2 /*return*/, false];
            case 4: return [2 /*return*/, true];
        }
    });
}
export function onPatchSellListing(_a) {
    var id, patch, redirect, rawListing, listing, updatedListing, userSettings, preUploaded, expiryDate, e_8, message;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 13, , 16]);
                id = payload.id, patch = payload.patch, redirect = payload.redirect;
                return [4 /*yield*/, call(listingsApi.getSellListingById, id)];
            case 1:
                rawListing = _d.sent();
                listing = rawSellListingToSellListing(rawListing);
                return [4 /*yield*/, put(loadSellListingDetailsSuccess(listing))];
            case 2:
                _d.sent();
                return [4 /*yield*/, call(patch, listing)];
            case 3:
                updatedListing = _d.sent();
                if (!isString(updatedListing)) return [3 /*break*/, 6];
                return [4 /*yield*/, put(patchSellListingFailure(updatedListing))];
            case 4:
                _d.sent();
                return [4 /*yield*/, call(toast.error, updatedListing)];
            case 5:
                _d.sent();
                return [2 /*return*/];
            case 6: return [5 /*yield**/, __values(isListingValidListing(updatedListing))];
            case 7:
                if (!!(_d.sent())) return [3 /*break*/, 9];
                return [4 /*yield*/, put(patchSellListingFailure('validation problem'))];
            case 8:
                _d.sent();
                return [2 /*return*/];
            case 9: return [4 /*yield*/, select(selectUserSettings)];
            case 10:
                userSettings = _d.sent();
                preUploaded = updatedListing.ticketsInfo.slice((_b = updatedListing.soldQuantity) !== null && _b !== void 0 ? _b : 0).filter(function (ti) { return ti.id; }).length > 0;
                expiryDate = updatedListing.expiryDateOverwritten
                    ? updatedListing.expiryDate
                    : getExpiryDateRange(updatedListing.event, userSettings, updatedListing.ticketType, preUploaded).after;
                return [4 /*yield*/, call(onPublishSellListing, publishSellListing(__assign(__assign({}, updatedListing), { expiryDate: expiryDate }), false, redirect))];
            case 11:
                _d.sent();
                return [4 /*yield*/, put(patchSellListingSuccess(id))];
            case 12:
                _d.sent();
                return [3 /*break*/, 16];
            case 13:
                e_8 = _d.sent();
                message = (_c = tryMapError(e_8)) !== null && _c !== void 0 ? _c : 'Your listing has not been saved!';
                return [4 /*yield*/, put(patchSellListingFailure(message))];
            case 14:
                _d.sent();
                return [4 /*yield*/, call(toast.error, message)];
            case 15:
                _d.sent();
                return [3 /*break*/, 16];
            case 16: return [2 /*return*/];
        }
    });
}
export function onPatchBuyListing(_a) {
    var id, patch, redirect, rawListing, listing, updatedListing, userSettings, expiryDate, e_9, message;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 13, , 16]);
                id = payload.id, patch = payload.patch, redirect = payload.redirect;
                return [4 /*yield*/, call(listingsApi.getBuyListingById, id)];
            case 1:
                rawListing = _c.sent();
                listing = rawBuyListingToBuyListing(rawListing);
                return [4 /*yield*/, put(loadBuyListingDetailsSuccess(listing))];
            case 2:
                _c.sent();
                return [4 /*yield*/, call(patch, listing)];
            case 3:
                updatedListing = _c.sent();
                if (!isString(updatedListing)) return [3 /*break*/, 6];
                return [4 /*yield*/, put(patchBuyListingFailure(updatedListing))];
            case 4:
                _c.sent();
                return [4 /*yield*/, call(toast.error, updatedListing)];
            case 5:
                _c.sent();
                return [2 /*return*/];
            case 6: return [5 /*yield**/, __values(isListingValidListing(updatedListing))];
            case 7:
                if (!!(_c.sent())) return [3 /*break*/, 9];
                return [4 /*yield*/, put(patchBuyListingFailure('validation problem'))];
            case 8:
                _c.sent();
                return [2 /*return*/];
            case 9: return [4 /*yield*/, select(selectUserSettings)];
            case 10:
                userSettings = _c.sent();
                expiryDate = updatedListing.expiryDateOverwritten
                    ? updatedListing.expiryDate
                    : getExpiryDateRange(updatedListing.event, userSettings, updatedListing.ticketType, false).after;
                return [4 /*yield*/, call(onPublishBuyListing, publishBuyListing(__assign(__assign({}, updatedListing), { expiryDate: expiryDate }), null, null, undefined, false, redirect))];
            case 11:
                _c.sent();
                return [4 /*yield*/, put(patchBuyListingSuccess(id))];
            case 12:
                _c.sent();
                return [3 /*break*/, 16];
            case 13:
                e_9 = _c.sent();
                message = (_b = tryMapError(e_9)) !== null && _b !== void 0 ? _b : 'Your listing has not been saved!';
                return [4 /*yield*/, put(patchBuyListingFailure(message))];
            case 14:
                _c.sent();
                return [4 /*yield*/, call(toast.error, message)];
            case 15:
                _c.sent();
                return [3 /*break*/, 16];
            case 16: return [2 /*return*/];
        }
    });
}
export function onUpdateListingDetailsQuantity(_a) {
    var quantityDiff, listingSnapshot, updatedListingSnapshot, hasUploadedTickets, e_10, message;
    var _b, _c, _d, _e;
    var payload = _a.payload;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _f.trys.push([0, 4, , 6]);
                quantityDiff = payload.quantityDiff, listingSnapshot = payload.listingSnapshot;
                updatedListingSnapshot = __assign(__assign({}, listingSnapshot), { availableQuantity: ((_b = listingSnapshot.availableQuantity) !== null && _b !== void 0 ? _b : 0) + quantityDiff, totalQuantity: ((_c = listingSnapshot.totalQuantity) !== null && _c !== void 0 ? _c : 0) + quantityDiff });
                hasUploadedTickets = (_d = updatedListingSnapshot.ticketsInfo) === null || _d === void 0 ? void 0 : _d.some(function (ti) { return ti.id; });
                return [4 /*yield*/, put(updateSellListingSnapshot(updatedListingSnapshot))];
            case 1:
                _f.sent();
                if (!hasUploadedTickets) return [3 /*break*/, 3];
                return [4 /*yield*/, put(push(routes.sellListingETickets('event', createSlug(listingSnapshot.event.id, ''), listingSnapshot.id)))];
            case 2:
                _f.sent();
                _f.label = 3;
            case 3: return [3 /*break*/, 6];
            case 4:
                e_10 = _f.sent();
                message = (_e = tryMapError(e_10)) !== null && _e !== void 0 ? _e : 'Your listing has not been saved!';
                return [4 /*yield*/, call(toast.error, message)];
            case 5:
                _f.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function onUpdateUserListingQuantity(_a) {
    var id, quantityDiff, listing, updatedListingSnapshot, hasUploadedTickets, e_11, message;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 5, , 7]);
                id = payload.id, quantityDiff = payload.quantityDiff;
                return [4 /*yield*/, call(listingsApi.getSellListingById, id)];
            case 1:
                listing = _d.sent();
                updatedListingSnapshot = __assign(__assign({}, rawSellListingToSellListing(listing)), { availableQuantity: listing.availableQuantity + quantityDiff, totalQuantity: listing.totalQuantity + quantityDiff });
                hasUploadedTickets = (_b = updatedListingSnapshot.ticketsInfo) === null || _b === void 0 ? void 0 : _b.some(function (ti) { return ti.id; });
                return [4 /*yield*/, put(updateSellListingSnapshot(updatedListingSnapshot))];
            case 2:
                _d.sent();
                if (!hasUploadedTickets) return [3 /*break*/, 4];
                return [4 /*yield*/, put(push(routes.sellListingETickets('user-listing', createSlug(listing.event.id, ''), listing.id)))];
            case 3:
                _d.sent();
                _d.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                e_11 = _d.sent();
                message = (_c = tryMapError(e_11)) !== null && _c !== void 0 ? _c : 'Your listing has not been saved!';
                return [4 /*yield*/, call(toast.error, message)];
            case 6:
                _d.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function onCancelListing(_a) {
    var id, type, rawSellListing, rawBuyListing, e_12;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 9, , 12]);
                id = payload.id, type = payload.type;
                if (!(type === 'sell')) return [3 /*break*/, 3];
                return [4 /*yield*/, call(listingsApi.cancelSellListing, id)];
            case 1:
                rawSellListing = _b.sent();
                return [4 /*yield*/, put(loadSellListingDetailsSuccess(rawSellListingToSellListing(rawSellListing)))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3: return [4 /*yield*/, call(listingsApi.cancelBuyListing, id)];
            case 4:
                rawBuyListing = _b.sent();
                return [4 /*yield*/, put(loadBuyListingDetailsSuccess(rawBuyListingToBuyListing(rawBuyListing)))];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [4 /*yield*/, put(loadUserListings())];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(cancelListingSuccess())];
            case 8:
                _b.sent();
                return [3 /*break*/, 12];
            case 9:
                e_12 = _b.sent();
                return [4 /*yield*/, put(cancelListingFailure())];
            case 10:
                _b.sent();
                return [4 /*yield*/, call(toast.error, 'Cannot cancel listing')];
            case 11:
                _b.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function listingsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest([loadUserListings.getType(), silentLoadUserListings.getType()], onLoadUserListings)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(publishSellListing.getType(), onPublishSellListing)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(publishBuyListing.getType(), onPublishBuyListing)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(publishListings.getType(), onPublishListings)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadSellListingDetails.getType(), onLoadSellListingDetails)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(loadBuyListingDetails.getType(), onLoadBuyListingDetails)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateSellListingSnapshotWithTickets.getType(), onUpdateSellListingSnapshotWithTickets)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLatest(convertPurchaseToSellListing.getType(), onConvertPurchaseToSellListing)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(patchSellListing.getType(), onPatchSellListing)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(patchBuyListing.getType(), onPatchBuyListing)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateListingDetailsQuantity.getType(), onUpdateListingDetailsQuantity)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeLatest(updateUserListingQuantity.getType(), onUpdateUserListingQuantity)];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeLatest(cancelListing.getType(), onCancelListing)];
            case 13:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
